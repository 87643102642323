import domToImage from 'dom-to-image'

function Screenshot() {}

Screenshot.prototype = {
  capture: function capture(el) {
    this.updateForm()

    return this.domToImg(el).catch(() => {
      // an error capturing the page, fallback to the fallback library
      // eslint-disable-next-line no-console
      console.log('reverting to fallback library')
      return this.h2cv(el)
    })
  },

  domToImg: function domToImg(el) {
    // if image can't be loaded, it puts this transparent 1x1 pixel in its place
    const fallback =
      'image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNsb2+tBwAFRAIUz681/AAAAABJRU5ErkJggg=='
    // filter to omit certain problematic elements
    const filt = function filt(node) {
      const name = node.localName
      const omit = ['iframe', 'script', 'svg', 'noscript']

      if (omit.includes(name)) {
        return false
      }

      // this is a workaround to remove comment nodes.
      // they break the screenshot tool
      if (node.nodeType === 8) {
        node.remove()
        return false
      }

      // exclude invisible nodes as they throw errors in FF
      try {
        const {display} = window.getComputedStyle(node)
        if (display === 'none') {
          return false
        }
        // eslint-disable-next-line no-empty
      } catch (e) {}

      return true
    }

    return domToImage.toPng(el, {filter: filt, imagePlaceholder: fallback})
  },

  getScript: () =>
    new Promise(resolve => {
      const script = document.createElement('script')
      script.src = 'https://cdnjs.cloudflare.com/ajax/libs/html2canvas/0.5.0-beta4/html2canvas.min.js'
      script.addEventListener('load', resolve)
      document.body.appendChild(script)
    }),
  // backup method
  h2cv: function h2cv(el) {
    return this.getScript().then(() => {
      const options = {
        allowTaint: false,
      }
      return window.html2canvas(el, options).then(canvas => canvas.toDataURL())
    })
  },

  // workaround to deal with the issue where elements aren't always set correctly
  updateForm: function updateForm() {
    // eslint-disable-next-line no-undef
    Array.from(document.querySelectorAll('input[type=checkbox]')).forEach(el => {
      const val = el.checked
      if (val) {
        el.setAttribute('checked', true)
      } else {
        el.removeAttribute('checked')
      }
    })

    // eslint-disable-next-line no-undef
    Array.from(document.querySelectorAll('select')).forEach(select => {
      const val = select.value
      Array.from(select.querySelectorAll('option')).forEach(o => {
        // eslint-disable-next-line eqeqeq
        if (o.value == val) {
          o.setAttribute('selected', true)
        } else {
          o.removeAttribute('selected')
        }
      })
    })
  },
}

export default Screenshot
