function EventEmitter() {
  this._events = {}
}

EventEmitter.prototype = {
  addListener(label, cb) {
    if (!this._events[label]) this._events[label] = []

    this._events[label].push(cb)
  },

  emit(label, ...args) {
    for (const handler of this._events[label] || []) {
      handler(...args)
    }
  },
}

export default EventEmitter
