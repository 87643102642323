import * as Cache from './cache'
import {debug, getFullLog, getMapiUrl} from './Env'
import {toArray} from './utils'
import {parseUriQuery, cookie, getStrippedDomain, putJSON, postJSON} from './web'
import {lapi} from './additional/ErrorLog'
import EventEmitter from './EventEmitter'

/**
 * Searches the data layer for the provided key; returns the last one pushed
 * @param {string} keyToFind
 * @returns any
 * */
export function getFromDataLayer<T>(keyToFind: string): T {
  const layer = window.dataLayer
  if (!layer || !layer.length) return undefined

  // Get the last element out, for cases of override
  for (let i = layer.length - 1; i >= 0; i--) {
    if (typeof layer[i][keyToFind] !== 'undefined') {
      return layer[i][keyToFind]
    }
  }
  return undefined
}

/**
 * "Empty" here is defined as values we don't want to overwrite previous
 * values in the datalayer. Literal 0 or false should be allowed to overwrite.
 */
function isEmpty(thing: any) {
  return thing === '' || thing === null || thing === undefined
}

export function datalayerPush(obj: any) {
  window.dataLayer = window.dataLayer ?? []
  window.data_layer = window.data_layer ?? []

  // make sure we only push empty values if there is not already a value
  const toPush: {[p: string]: any} = {}
  for (const key of Object.keys(obj || {})) {
    const val = obj[key]
    const present = getFromDataLayer(key)
    if (!isEmpty(val) || isEmpty(present)) {
      toPush[key] = val
    }
  }
  if (Object.keys(toPush).length > 0) {
    window.dataLayer.push(toPush)
    // push to data_layer to support old code
    window.data_layer.push(toPush)
  }
}

export function invalidateDataLayer() {
  Cache.invalidateCache()
  if (getFromDataLayer('request_id')) {
    for (const layer of ['dataLayer', 'data_layer']) {
      for (const obj of (window as any)[layer]) {
        if ('request_id' in obj) {
          delete obj.request_id
        }
      }
    }
  }
  setRequestIdCookie('', -3600)
  cookie('mapiJsPromo', '', -3600)
}

export function setRequestIdCookie(requestId: string, ttlSeconds: number = 30 * 60) {
  cookie('clRequestId', requestId, ttlSeconds, '/', getStrippedDomain())
}

let mapiReadyFired = false

export function fireMapiRequestIdReady(requestId: string) {
  if (mapiReadyFired) {
    return
  }
  mapiReadyFired = true
  const event = new CustomEvent('mapiRequestIdReady', {
    bubbles: true,
    detail: {requestId},
  })
  document.dispatchEvent(event)
}

export function setDataLayerRequestId(requestId: string) {
  const prevDl = getDataLayerRequestId()
  if (requestId === prevDl) {
    fireMapiRequestIdReady(requestId)
    return
  }
  datalayerPush({request_id: requestId})
  fireMapiRequestIdReady(requestId)
}

export function getDataLayerRequestId(): string {
  // Fall back to the global cl_gtm if we can't find it in the data layer.
  const dlId = getFromDataLayer<string>('requestID') || getFromDataLayer<string>('request_id')
  const clGtmId = window.cl_gtm && window.cl_gtm.requestID

  return dlId || clGtmId
}

const maxUpdateSession = 4
let newSessionCallCount = 0

export function handleExcessiveCall() {
  const log = [
    getFullLog(),
    Cache.getMapiCache(),
    // eslint-disable-next-line no-undef
    document.cookie,
    new Error().stack,
  ]

  lapi.log(JSON.stringify(log), 'ERROR')
}

/**
 * Push the event to the dataLayer
 * @param {{event: string|any}} obj
 */
export function datalayerEvent(obj: any) {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(obj)
}

// Load the customer ID from local storage, falling back to the cookie
export function getGuid() {
  return Cache.getCacheItem('clGuid') || cookie('clGuid')
}

export const dataLayerEventEmitter = new EventEmitter()
export const addEventListener = (e: string, cb: Function) => dataLayerEventEmitter.addListener(e, cb)
export const removeEventListener = () => {}
export const trigger = function t(...args: any[]) {
  dataLayerEventEmitter.emit(dataLayerEventEmitter, ...args)
}

// Store the customer ID in local storage, the MAPI cache, and a 3-year cookie
function setGuid(guid: string) {
  // 3 years
  const ttlSeconds = 60 * 60 * 24 * 365 * 3
  cookie('clGuid', guid, ttlSeconds)
  Cache.setCacheItem('clGuid', guid)

  if (getFromDataLayer('visitor_id') !== guid) {
    datalayerPush({visitor_id: guid})
    datalayerPush({clGuid: guid})
    datalayerEvent({event: 'clDatalayerGuid'})
  }
}

export function handleMapiResponse(
  response: any,
  allowLastResort?: boolean,
  domain?: string,
  promoCode?: string | number,
  extraData?: any,
): Promise<any> {
  const reqId = response?.data?.request_id
  const phoneResponses = response?.data?.phone?.data

  if (!reqId) {
    debug('No request ID returned', response)
    return Promise.resolve({})
  }

  setRequestId(reqId)
  const knownTokens = Cache.getCacheItem('knownTokens') || {}

  phoneResponses.forEach((p: any) => {
    if (p.is_last_resort && !allowLastResort) return
    knownTokens[p.token] = `${p.promo_number}`
  })

  Cache.setCacheItem('requestData', {
    requestId: reqId,
    extraData,
    domain,
    promo: promoCode,
    fullResponse: response,
  })

  Cache.setCacheItem('knownTokens', knownTokens)

  const returnedPromo = response?.data?.promo_code
  if (!getPromoCode() && returnedPromo) {
    datalayerPush({promo_code: returnedPromo})
    if (window.cl_gtm) window.cl_gtm.promoCode = returnedPromo
    Cache.setCacheItem('lastPromo', getPromoCode())
  }

  // store the customer id if returned
  const guidSet = response?.data?.customer_id?.data?.[0]?.guid
  if (guidSet) {
    setGuid(guidSet)
  }

  return Promise.resolve({tokens: knownTokens, response})
}

interface Token {
  brand?: string
  death?: boolean
}

export class CPRRequest {
  private extraData: {[p: string]: any} = {}

  private tokensToSend: string[] = []

  private shouldAllowRotation = true

  private allowLastResort = false

  private sourcePath = window.location.pathname

  private failure: Function

  constructor(
    private promoCode?: string | number,
    private brand?: string,
    private domain?: string,
    private requestId?: string,
  ) {}

  tokens(ts: string[] | Token[]): CPRRequest {
    this.tokensToSend = ts
      .filter(t => t)
      .map(t => {
        if (typeof t === 'object') {
          t = t.brand
        }
        return `${t}`.replace(/^\s+|\s+$/g, '')
      })
    return this
  }

  extra(key: string, data: any): CPRRequest {
    this.extraData[key] = data
    return this
  }

  catch(fn: Function): CPRRequest {
    this.failure = fn
    return this
  }

  allowRotation(allow: boolean): CPRRequest {
    this.shouldAllowRotation = !!allow
    return this
  }

  path(p: string): CPRRequest {
    this.sourcePath = p
    return this
  }

  track(): CPRRequest {
    this.requestId = this.requestId || getRequestId()
    return this
  }

  allowLastResortNumber(l: boolean): CPRRequest {
    this.allowLastResort = l
    return this
  }

  private performInitialRequest(newTokens: string[]): Promise<any> {
    const data: {data: {[p: string]: any}} = {
      data: {
        request: {
          brand: this.brand,
          promo_code: this.promoCode,
          domain: this.domain,
          path: this.sourcePath || window.location.pathname,
        },
        tokens: newTokens || [],
      },
    }

    Object.entries(this.extraData).forEach(([key, value]) => {
      data.data.request[key] = value
    })

    const url = getMapiUrl('/cpr/external')
    return postJSON(url, data)
  }

  private performTokenRequest(reqId: string, newTokens: string[]): Promise<any> {
    const data = {
      data: {
        domain: this.domain,
        tokens: newTokens,
      },
    }

    const url = getMapiUrl(`/cpr/external/request/${reqId}/tokens`)
    return postJSON(url, data)
  }

  private handleFailure(err: Error) {
    debug('Failure in request to MAPI: ', err)
    if (this.failure) this.failure.call(err)
  }

  send(): Promise<any> {
    const knownTokens = Cache.getCacheItem('knownTokens') || {}
    if (this.brand) {
      this.tokensToSend.unshift(this.brand)
    }

    const anyExtra = Object.keys(this.extraData).length

    // only send an actual request if we don't have tokens that are being requested,
    // or if there is extra data to send
    const tokensToFetch = toArray(this.tokensToSend).filter(t => typeof knownTokens[t] === 'undefined')
    this.requestId = this.requestId || getRequestId()

    if (this.requestId) {
      if (anyExtra) updateRequest(this.requestId, this.extraData).catch(console.error)

      if (tokensToFetch.length && this.shouldAllowRotation) {
        return this.performTokenRequest(this.requestId, tokensToFetch)
          .then(r => handleMapiResponse(r, this.allowLastResort, this.domain, this.promoCode, this.extraData))
          .catch(this.handleFailure.bind(this))
      }
      return Promise.resolve({tokens: knownTokens, response: {}})
    }
    if (tokensToFetch.length && this.shouldAllowRotation) {
      return this.performInitialRequest(tokensToFetch)
        .then(r => handleMapiResponse(r, this.allowLastResort, this.domain, this.promoCode, this.extraData))
        .catch(this.handleFailure.bind(this))
    }

    return Promise.resolve({tokens: knownTokens, response: {}})
  }
}

/**
 * Update a particular request on MAPI with the provided extra data.
 * @param {string} requestId
 * @param {object} extraData
 * @param {function(): void} [cb]
 */
export async function updateRequest(requestId: string, extraData: any, cb?: (r: any) => void): Promise<any> {
  if (!requestId) {
    return Promise.reject(new Error('updateRequest called with no requestId'))
  }

  const url = getMapiUrl(`/cpr/external/request/${requestId}`)
  return putJSON(url, {
    data: {
      request: extraData,
    },
  }).then(r => {
    handleMapiResponse(r)
    if (cb) cb(r)
    return r
  })
}

export function updateForNewSession(oldRequestId?: string, newRequestId?: string) {
  newSessionCallCount += 1

  if (newSessionCallCount > maxUpdateSession) {
    debug('Excessive call count for updateForNewSession.')
    handleExcessiveCall()
    return
  }

  debug('Updating for new session')
  if (window.runMapiModules) {
    debug('running MAPI modules')
    window.runMapiModules(true)
  }

  if (window.piwikData && window.piwikData.updateVariables) {
    debug('running piwik update')
    window.piwikData.updateVariables()
  }

  if (oldRequestId && newRequestId) {
    debug('running request ID link')

    updateRequest(newRequestId, {
      linked_requests: {
        request_id: newRequestId,
        previous_request_id: oldRequestId,
      },
    })
  }
}

// Invalidate the current mapi-js session: clear the request ID and
export function setRequestId(requestId: string) {
  if (!requestId) return

  const cached = Cache.getCacheItem('requestId')
  const previous = Cache.getCacheItem('previousRequestIds') || {}
  const incoming = requestId

  debug('cached:', cached)
  debug('previous:', previous)
  debug('incoming:', incoming)
  if (!cached) {
    Cache.setCacheItem('requestId', requestId)
    Cache.setCacheItem('previousRequestIds', previous)
    setDataLayerRequestId(requestId)
    setRequestIdCookie(requestId)
    return
  }

  if (cached && cached !== incoming) {
    if (previous && previous[incoming]) {
      debug('not clearing session, detected request ID cycle')
      return
    }

    previous[cached] = true

    debug('invalidating for mismatched request ID during set')
    invalidateDataLayer()

    Cache.setCacheItem('requestId', requestId)
    Cache.setCacheItem('previousRequestIds', previous)
    setDataLayerRequestId(requestId)
    setRequestIdCookie(requestId)

    updateForNewSession(cached, incoming)
  } else {
    setDataLayerRequestId(requestId)
  }
}

/**
 * Look for a cached request ID. Also checks the dataLayer object, since CMS stores things there.
 * @returns string
 */
export function getRequestId(): string {
  const cached = Cache.getCacheItem('requestId')
  let current = getDataLayerRequestId()

  if (cached && !current) {
    setDataLayerRequestId(cached)
    return cached
  }

  if (current && cached && cached !== current) {
    debug('invalidating for mismatched request ID')
    invalidateDataLayer()
  }

  if (!current) {
    // only check the cookie if we don't have a current request ID at all
    current = cookie('clRequestId')
  }

  if (current && current !== cached) {
    setRequestId(current)
  }

  return current || ''
}

export function getPromoCode() {
  const promo =
    validOrNullify(parseUriQuery().kbid as string) ||
    debug('cl_gtm') ||
    (window.cl_gtm && validOrNullify(window.cl_gtm.promoCode)) ||
    debug('dl promoCode') ||
    validOrNullify(getFromDataLayer('promoCode')) ||
    debug('dl promo_code') ||
    validOrNullify(getFromDataLayer('promo_code')) ||
    debug('cookie promo') ||
    validOrNullify(cookie('promo')) ||
    debug('cookie mapiJsPromo') ||
    validOrNullify(cookie('mapiJsPromo')) ||
    debug('cache lastPromo') ||
    validOrNullify(Cache.getCacheItem('lastPromo')) ||
    debug('config defaultPromoCode') ||
    validOrNullify(window.MAPI?.config?.defaultPromoCode)

  if (!promo) return null
  return `${promo}`.replace(/^(\d+).*/, '$1')
}

export function validOrNullify(promo: string | number): number {
  if (typeof promo === 'string') {
    promo = Number(promo)
  }

  if (typeof promo === 'number' && Number.isInteger(promo) && promo >= 0 && promo <= 9999999) {
    return promo
  }

  return null
}
