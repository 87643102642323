export const ENV_DEV = 'development'
export const ENV_STAGING = 'staging'
export const ENV_PRODUCTION = 'production'

export const levels = {
  debug: 100,
  info: 200,
  notice: 250,
  error: 300,
  critical: 500,
}

export const levelNames = Object.entries(levels).reduce((acc, [name, level]) => {
  acc[level] = name
  return acc
}, {})

export const LEVEL_DEBUG = 'debug'
export const LEVEL_INFO = 'info'
export const LEVEL_NOTICE = 'notice'
export const LEVEL_ERROR = 'error'
export const LEVEL_CRITICAL = 'critical'

const clLog = []

export const mapiUrls = {
  [ENV_DEV]: '//mapi.loc',
  [ENV_STAGING]: 'https://mapi.stage.clear.link',
  [ENV_PRODUCTION]: 'https://m.clear.link',
}

export const tcpaUrls = {
  [ENV_DEV]: 'https://proof.dev.aws.clear.link',
  [ENV_STAGING]: 'https://proof.dev.aws.clear.link',
  [ENV_PRODUCTION]: 'https://proof.clear.link',
}

export function getLogLevel() {
  return (window.MAPI && window.MAPI.logLevel) || levels[LEVEL_ERROR]
}

export function pushToInMemoryLog(level, args) {
  clLog.push([level, [...args]])
}

export function log(level, args, force = false) {
  if (!force || getLogLevel() >= level) return
  // eslint-disable-next-line no-console
  if (!console || !console.error) return

  pushToInMemoryLog(level, args)

  try {
    // eslint-disable-next-line no-console
    console.log(`[${levelNames[level] || 'none'}]`, ...args)
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

export function debug(...args) {
  log(levels.debug, args)
}

export function info(...args) {
  log(levels.info, args)
}

export function notice(...args) {
  log(levels.notice, args)
}

export function error(...args) {
  log(levels.error, args)
}

export function critical(...args) {
  log(levels.critical, args)
}

export function isValidEnv(value) {
  return value !== undefined && [ENV_DEV, ENV_STAGING, ENV_PRODUCTION].indexOf(value) !== -1
}

// set the global environment. not ideal to be accessing `global` here, but since
// multiple entrypoints access the MAPI env, we need it to be consistent
export function setEnv(value) {
  if (!isValidEnv(value)) return
  window.MAPI_ENV = value
}

export function getEnv() {
  return window.FORCE_MAPI_ENV || window.MAPI_ENV
}

/**
 * Determine the correct MAPI url. Checks a global, then tries to determine the correct MAPI url
 * given the current window.location.
 *
 * @returns string
 */
export function getMapiUrl(path, environment = getEnv()) {
  const url = !isValidEnv(environment) ? mapiUrls[ENV_PRODUCTION] : mapiUrls[environment]
  return `${url}${path}`
}

export function getTcpaUrl(environment = null) {
  return isValidEnv(environment) ? tcpaUrls[environment] : tcpaUrls[ENV_PRODUCTION]
}

export function normalizeLogLevel(nameOrLevel) {
  return levels[nameOrLevel] || nameOrLevel
}

export function setLogLevel(l) {
  window.MAPI = window.MAPI || {}
  window.MAPI.logLevel = normalizeLogLevel(l)
  // eslint-disable-next-line no-console
  console.log(`set log level to ${window.MAPI.logLevel}`)
  return l
}

export function getFullLog() {
  return clLog
}

if (typeof window !== 'undefined') {
  window.setLogLevel = setLogLevel
  window.getLogLevel = getLogLevel
}

export function allowFingerprintSend() {
  return false
}
export function setNoFingerprintAllowed() {}
