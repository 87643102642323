import {error} from './Env'

export interface RequestOptions {
  accept?: string
  contentType?: string
  data?: any
}
/**
 * Exists since we really can't rely on jQuery being everywhere we need MAPI communication.
 *
 * @param baseUrl string URL, *without query string*, to hit.
 * @param method string "GET", "POST", "PUT"
 * @param opts object Supports:
 * {
 *   headers: {'Content-Type': 'application/json'}, // Object representing header data
 *   accept: 'json', // Tries to parse response as JSON, regardless of incoming content type
 *   type: 'json', // Encodes the incoming data as JSON if POST or PUT, rather than form-encoded
 *   data: {some: 'data', to: 'use'}, // Forms the query string if GET, post data if POST or PUT
 *   success: function(response, xhr) {} // Success callback. Response is parsed response text if possible
 *   fail: function(response, xhr) {} // Failure callback. Response is parsed response text if possible
 * }
 * @returns {Promise}
 */
export async function request<T>(baseUrl: string, method: string, opts?: RequestOptions): Promise<T> {
  let data = opts?.data ?? ''
  let url = baseUrl
  if (method === 'GET') {
    const query = new URLSearchParams(data).toString()
    if (query.length) url += `?${query}`
  } else if (typeof data !== 'string') {
    if (opts?.contentType === 'application/json') data = JSON.stringify(data)
    else data = new URLSearchParams(data).toString()
  }

  return fetch(url, {
    method,
    body: data,
    headers: {
      Accept: opts?.accept ?? 'application/json',
      'Content-Type': opts.contentType ?? 'application/json',
    },
  }).then(res => {
    if (!opts?.accept || opts?.accept === 'application/json') {
      return res.json()
    }
    return res.text()
  })
}

/**
 * Simplified method for performing a GET expecting JSON in return.
 *
 * @param url string
 * @param data object
 * @returns {Promise}
 */
export function getJSON<T>(url: string, data?: any): Promise<T> {
  return request(url, 'GET', {data, contentType: 'application/json', accept: 'application/json'})
}

/**
 * Simplified method for posting JSON, expecting JSON in return.
 *
 * @param url
 * @param data
 * @returns {Promise}
 */
export async function postJSON<T>(url: string, data: any): Promise<T> {
  return request(url, 'POST', {data, contentType: 'application/json', accept: 'application/json'})
}

/**
 * Simplified method for PUT'ing JSON, expecting JSON in return.
 *
 * @param url
 * @param data
 * @returns {XMLHttpRequest|Promise}
 */
export async function putJSON<T>(url: string, data: any): Promise<T> {
  return request(url, 'PUT', {data, contentType: 'application/json', accept: 'application/json'})
}

/**
 * Decodes the current location's query string into an object
 * @returns {*}
 */
export function parseUriQuery(): Record<string, string | string[]> {
  return Object.fromEntries(new URLSearchParams(window.location.search || ''))
}

export function cookie(
  name: string,
  value?: string,
  ttlSeconds?: number,
  path?: string,
  domain?: string,
  secure?: boolean,
): string {
  const prefix = window.MAPI?.config?.cookiePrefix ? window.MAPI.config.cookiePrefix + name : name

  if (arguments.length > 1) {
    document.cookie = `${prefix}=${encodeURIComponent(value)}${
      ttlSeconds ? `; expires=${new Date(+new Date() + ttlSeconds * 1000).toUTCString()}` : ''
    }${path ? `; path=${path}` : ''}${domain ? `; domain=${domain}` : ''}${secure ? '; secure' : ''}`
    return document.cookie
  }

  return decodeURIComponent((`; ${document.cookie}`.split(`; ${prefix}=`)[1] || '').split(';')[0])
}

export function jsonCookie(
  name: string,
  value?: string,
  ttl?: number,
  path?: string,
  domain?: string,
  secure?: boolean,
): object | string {
  if (arguments.length > 1) {
    return cookie(name, JSON.stringify(value), ttl, path, domain, secure)
  }

  try {
    return JSON.parse(cookie(name))
  } catch (e) {
    return null
  }
}

export function parseDomain(location: string | Location = null): string {
  if (!location) {
    return window.location ? window.location.hostname : ''
  }
  if (typeof location === 'object') {
    location = location.host
  }

  try {
    if (!location.startsWith('http') && !location.startsWith('//')) {
      // eslint-disable-next-line no-param-reassign
      location = `https://${location}`
    }
    const parsed = new URL(location)
    if (!parsed) return ''
    if (parsed.href && !parsed.hostname) {
      return location.replace(/\/.*$/g, '')
    }

    return parsed.hostname
  } catch (e) {
    error('error parsing URL', e)
    return location
  }
}

export function getStrippedDomain(location: string | Location = null): string {
  const host = parseDomain(location)
  const split = host?.split('.')
  // Subdomain support, excluding pantheon multidevs
  if (host?.includes('pantheonsite') || split.length <= 2) {
    return host
  }
  return split?.slice(1).join('.')
}
